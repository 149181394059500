@import 'assets/styles/mixins.scss';

.topbar {
  background: $white;
  padding: 0 rem(20);
  min-height: 64px;
  height: 64px;
  border-bottom: 1px solid $gray-border;
  color: $green-dark;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  font-family: $primary-font;
}

.title {
  flex: 1 40%;
  margin-left: rem(30);
  font-size: xx-large;
}

.code {
  flex: 1 5%;
  font-size: large;
}

.height {
  flex: 1 5%;
  font-size: large;
}

.weight {
  flex: 1 4%;
  font-size: large;
}

.name {
  flex: 1 6%;
  font-size: large;
}

.logout {
  flex: 1 2%;
  font-size: large;
  cursor: pointer;
  margin-right: 2%;
}

.icon {
  margin-left: rem(10);
}

@media print {
  .logout {
    display: none;
  }
}
